<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_ppkedos',
      headers: [
        [{
          label: 'Waktu Pelaksanaan',
          dataKey: 'waktu_pelaksanaan',
        }, {
          label: 'Sasaran',
          dataKey: 'sasaran',
        }, {
          label: 'Hasil',
          dataKey: 'hasil',
        }, {
          label: 'Tindak Lanjut',
          dataKey: 'tindak_lanjut',
        }]
      ],
      form: {
        inputs: [{
          label: 'Waktu Pelaksanaan',
          name: 'waktu_pelaksanaan',
          dataKey: 'waktu_pelaksanaan',
          type: 'date',
          rules: 'required',
          flatPickrConfig: {
            dateFormat: 'F Y',
            altFormat: 'F Y',
            ariaDateFormat: 'F Y',
          }
        }, {
          label: 'Sasaran',
          name: 'sasaran',
          dataKey: 'sasaran',
          type: 'textarea',
        }, {
          label: 'Hasil',
          name: 'hasil',
          dataKey: 'hasil',
          type: 'textarea',
        }, {
          label: 'Tindak Lanjut',
          name: 'tindak_lanjut',
          dataKey: 'tindak_lanjut',
          type: 'textarea',
        }]
      },
    }
  },
}
</script>
